import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import { AuthContext } from "../Context/auth";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Typography from "@mui/material/Typography";
import ColorButton from "../Components/Button/colorbutton";

function Login() {
  const { login } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [CpfCnpj, setCpfCnpj] = useState("");
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const removeQueryParams = () => {
    //delete each query param
    searchParams.delete("nr_cpfcnpj");
    //update state after
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const nr_cpfcnpj = searchParams.get("nr_cpfcnpj");
    if (nr_cpfcnpj) {
      setCpfCnpj(nr_cpfcnpj);
      removeQueryParams();
    }
  }, [searchParams]);

  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.height = "100%";
    }
    return () => {
      if (root) {
        root.style.height = "auto";
      }
    };
  }, []);

  function mostraErro(texto) {
    setLoading(false);
    const Alerta = withReactContent(Swal);
    return Alerta.fire({
      title: <p>Atenção</p>,
      text: texto,
      icon: "warning",
    });
  }

  function alterarCpfCnpj(event) {
    setStatus("");
    setCpfCnpj(event.target.value);
  }

  async function _EntrarSemCertificado() {
    setStatus("");
    setLoading(true);

    setTimeout(async () => {
      try {
        const isLogged = await login(CpfCnpj, mostraErro);
        if (isLogged) {
          navigate("/inicio ");
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        // eslint-disable-next-line
        setStatus(error?.message);
        console.error(error);
      }
    }, 1000);
  }

  return (
    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative">
        <div
          className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y"
          style={{ backgroundColor: "#8D0000" }}
        >
          <div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">
            <a href="https://www.consultoriasolucao.com" className="py-9 mb-1">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/img/logo.png")}
                className="h-60px"
              />
            </a>
            <a href="https://www.consultoriasolucao.com">
              <h1 className="fs-1qx pb-2 " style={{ color: "#FFFFFF" }}>
                Solução Sistemas
              </h1>
            </a>
            <h1 className="fs-2qx pb-2 pb-md-5" style={{ color: "#FFFFFF" }}>
              Fatura fácil
            </h1>

            <p className="fw-bold fs-3" style={{ color: "#FFFFFF" }}>
              Tenha acesso as suas Faturas
              <br />
              dos sistemas SCA e Fiscal Fácil
            </p>
            <p className="text-700 fs-1" style={{ color: "#FFFFFF" }}>
              (67) 4042 - 7490
            </p>
          </div>
          <a href="https://www.consultoriasolucao.com">
            <div
              className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-250px"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/img/logo-solucao.png"
                )})`,
              }}
            ></div>
          </a>
        </div>
      </div>

      <div className="d-flex flex-column flex-lg-row-fluid py-10">
        <div className="d-flex flex-center flex-column flex-column-fluid">
          <div className="w-lg-500px p-10 p-lg-15 mx-auto">
            <div className="m-login__signin">
              <form
                className="form w-100"
                noValidate="novalidate"
                id="kt_sign_in_form"
                action="#"
              >
                {status && (
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">{status}</div>
                  </div>
                )}
                <div>
                  <div className="text-center mb-10">
                    <h1 className="text-dark mb-3"> Fatura Fácil</h1>
                    <div className="text-gray-400 fw-bold fs-4">
                      Faça seu login abaixo
                    </div>
                  </div>

                  <div className="fv-row mb-10">
                    <label
                      className="form-label fs-6 fw-bolder"
                      style={{ color: "#353535" }}
                    >
                      Acesse sua conta
                    </label>
                    <input
                      onChange={alterarCpfCnpj}
                      value={CpfCnpj}
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      id="cpfCnpj"
                      placeholder="CPF ou CNPJ"
                      tabIndex="1"
                      autoFocus={true}
                    />
                  </div>

                  <div className="text-center">
                    <ColorButton
                      type="button"
                      id="kt_sign_in_submit"
                      className="btn btn-lg w-100 mb-5"
                      disabled={loading}
                      onClick={async () => await _EntrarSemCertificado()}
                    >
                      {!loading && (
                        <span className="indicator-label">Acessar</span>
                      )}
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Por favor, aguarde...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </ColorButton>
                  </div>
                </div>
              </form>
            </div>
            <div className="d-flex align-items-center">
              <Typography sx={{ mt: 1 }} variant="subtitle2" component="div">
                Caso precise falar com um atendente, envie uma mensagem para o
                WhatsApp:
                <a
                  href="https://api.whatsapp.com/send?phone=5567981913166&text="
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  (67) 98191-3166
                </a>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

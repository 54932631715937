import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#ED3237"),
  backgroundColor: "#ED3237",
  "&:hover": {
    backgroundColor: "#ED5237",
  },
}));

export default ColorButton;

import React, { forwardRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import DrawerFaturaAberta from "./DrawerFaturaAberta";
import ColorButton from "../Button/colorbutton";
import "./styles.css";

const dayjs = require("dayjs");
dayjs().format();

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

function Tabela(props) {
  const faturas = props.faturas;
  const [statusModal, setStatusModal] = React.useState(false);
  const [faturaSelecionada, setfaturaSelecionada] = React.useState({});

  const totaFaturas = faturas.length > 0 ? faturas.length : 0;

  const handleAbrirModal = () => setStatusModal(true);
  const handleFecharModal = () => setStatusModal(false);

  const [stateSnackbar, setStateSnackbar] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = stateSnackbar;

  const mostrarSnackbar = (newState) => () => {
    setStateSnackbar({ open: true, ...newState });
  };

  const fecharSnackbar = (event, reason) => {
    setStateSnackbar({ ...stateSnackbar, open: false });
  };

  function handleClick(event, fatura) {
    setfaturaSelecionada(fatura);
    /*
    if (props.bloquear === true) {
      props.handleAbrirModalBloqueado();
    } else {
      handleAbrirModal();
    }
    */
    handleAbrirModal();
  }

  const numberFormat = (value) =>
    new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);

  return (
    <>
      <div className="card card-xl-stretch mb-xl-8">
        <div className="card-body py-3">
          <form className="form" id="searchOrder">
            <div className="d-flex align-items-center">
              <div className="position-relative min-w-400px me-2">
                <div className="row g-12">
                  <div className="col-12">
                    <div className="d-flex align-items-center">
                      {totaFaturas <= 0 ? (
                        <div>
                          <Typography gutterBottom variant="h5" component="p">
                            Não há faturas em aberto
                          </Typography>
                        </div>
                      ) : (
                        <Typography gutterBottom variant="h5" color="p">
                          Faturas em aberto
                        </Typography>
                      )}
                    </div>

                    <div className="mb-20">
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "flex-start",
                          "& > :not(style)": {
                            m: 1,
                            width: 138,
                            height: "100%",
                          },
                        }}
                      >
                        {faturas.map((fatura) => (
                          <section
                            key={fatura.cd_prl}
                            style={{
                              margin: "3px",
                              padding: "3px",
                            }}
                          >
                            <Paper elevation={3}>
                              <Card sx={{ minWidth: 135 }} variant="outlined">
                                <CardActionArea
                                  onClick={(event) =>
                                    handleClick(event, fatura)
                                  }
                                >
                                  <CardContent>
                                    <span className="badge badge-secondary">
                                      Em aberto
                                    </span>
                                    <Typography
                                      gutterBottom
                                      variant="p"
                                      component="div"
                                    >
                                      {dayjs(fatura.dt_venctobase).format(
                                        "MMMM YYYY"
                                      )}
                                    </Typography>
                                    <Typography
                                      gutterBottom
                                      variant="h5"
                                      color="text.danger"
                                    >
                                      {numberFormat(fatura.vl_total)}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {dayjs(fatura.dt_venctobase).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </Typography>
                                    <ColorButton
                                      style={{
                                        marginTop: "10px",
                                        padding: "5px 10px",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "4px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation(); // Prevenir o acionamento do clique do Card
                                        handleClick(event, fatura);
                                      }}
                                    >
                                      Ver boleto
                                    </ColorButton>
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Paper>
                          </section>
                        ))}
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <DrawerFaturaAberta
        fatura={faturaSelecionada}
        statusModal={statusModal}
        handleFecharModal={handleFecharModal}
        mostrarSnackbar={mostrarSnackbar}
        downloadPDF={props.downloadPDF}
      />
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={fecharSnackbar}
        TransitionComponent={TransitionLeft}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert onClose={fecharSnackbar} severity="success" sx={{ width: 200 }}>
          Código copiado!
        </Alert>
      </Snackbar>
    </>
  );
}

export default Tabela;
